import * as React from 'react';
import 'antd/dist/antd.css';
import { Drawer, Button } from 'antd';
import './hamenu.scss';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import UserContext from '../contexts/userContext';

function HamburgerMenu() {
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const user = React.useContext(UserContext);

  const logout = async (ev) => {
    ev.preventDefault();
    await Axios.get('/api/logout');
    history.push('/login');
  };

  return (
    <>
      <Button className="ham-btn" onClick={showDrawer}>
        ☰
      </Button>
      <Drawer
        title="תפריט"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <div>
          <span>
            ברוך הבא
            {' '}
            {user?.name}
            {' '}
            {user?.lastName || ''}
          </span>
        </div>
        <br />
        <div>
          <span><b>הטלפון שלי :</b></span>
          <span>
            {' '}
            {user?.phoneNumber}
          </span>
        </div>
        <br />
        <div className="menu-items">
          <ul>
            {user?.isGuide && <li><Link to="/admin/guide">ניהול תלמידים</Link></li>}
            <br />
            {user?.isGuide && <li><Link to="/guide/manage-bonuses">ניהול בונוסים</Link></li>}
            <br />
            {user?.isGuide && <li><Link to="/guide/manage-tasks">ניהול בחנים</Link></li>}
            <br />
            {user?.isManager && <li><Link to="/admin/manager">מסך ניהול - מנהלים</Link></li>}
          </ul>
        </div>
        <hr />
        <br />
        <div>
          <a href="/login" onClick={logout}>התנתק</a>
        </div>
      </Drawer>
    </>
  );
}

export default HamburgerMenu;
