import * as React from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../contexts/userContext';
import './StudentMainPage.scss';
import NavbarTorah from '../images/navbar-torah.png';
import NavbarMap from '../images/navbar-map.png';
import AchievementsMap from '../images/navbar-achievements.png';
import Logo from '../components/Logo';
import { daysToBarMitzva, getHebBarMitzvaStr } from '../utils/dates';
import { getUserSettings, setUserSetting } from '../services/userSettingsStorage';
import PlayIcon from '../images/icons/play.png';
import PauseIcon from '../images/icons/pause.png';
import UserRanks from '../components/UserRank';
import avatars from '../enums/avatars';
import DefaultAvatar from '../images/default-avatar-header.png';

const StudentMainHeader = ({ children }) => (
  <div className="student-main-header">
    <div className="student-main-header-bg">
      {children}
    </div>
  </div>
);

const StudentMainPage = ({ children }) => {
  const history = useHistory();
  const user = React.useContext(UserContext);
  const [isPlayingJingle, setIsPlayJingle] = React.useState(false);
  const audioRef = React.useRef(null);

  const toggleAudio = () => {
    if (isPlayingJingle) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlayJingle(!isPlayingJingle);
  };

  React.useEffect(() => {
    const userSettings = getUserSettings();
    if (!userSettings.jinglePlayed) {
      setIsPlayJingle(true);
      setUserSetting({ key: 'jinglePlayed', value: true });
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="student-main-page">
      <div>
        {user && (
          <StudentMainHeader>
            <div className="avatar-header">
              <div
                tabIndex={0}
                role="button"
                className="default-header-avatar"
                onClick={() => history.push('/user/profile')}
              >
                <img src={avatars[user.avatar]?.image || DefaultAvatar} alt="avatar" />
                <span>{user.name}</span>
              </div>
              <div className="avatar-header-stars-wrapper">
                <div className="avatar-stars" />
                <span>{user.stars}</span>
              </div>
            </div>
            <div className="user-ranks-wrapper">
              <UserRanks user={user} />
            </div>
            <div className="today-date">
              <span>
                {getHebBarMitzvaStr(user.birthDate, user.afterSunset)}
&nbsp;|&nbsp;
              </span>
              <span>
                {daysToBarMitzva(user.birthDate, user.afterSunset)}
                {' '}
                ימים לבר-מצווה
              </span>
            </div>
            {history.location.pathname === '/' && (
              <div>
                <button className="naive-button jingle-button" type="button" onClick={toggleAudio}>
                  {isPlayingJingle ? <img src={PauseIcon} alt="השהה ג'ינגל" /> : <img src={PlayIcon} alt="נגן ג'ינגל" />}
                </button>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio ref={audioRef} src="/tracks/jingle.mp3" />
              </div>
            )}
            <div className="student-main-logo-wrapper"><Logo /></div>
          </StudentMainHeader>
        )}
      </div>
      <div>
        {children}
      </div>
      <div className="student-main-page-footer-bg">
        <div className="student-main-navbar">
          <div className={`nav-item${history.location.pathname === '/parasha' ? ' selected' : ''}`} tabIndex="0" role="button" onClick={() => history.push('/parasha')}>
            <div><img src={NavbarTorah} alt="קריאה בתורה" /></div>
            <div><span>קריאה בתורה</span></div>
          </div>
          <div className={`nav-item${history.location.pathname === '/' ? ' selected' : ''}`} tabIndex="0" role="button" onClick={() => history.push('/')}>
            <div><img src={NavbarMap} alt="מפה" /></div>
            <div><span>מפה</span></div>
          </div>
          <div className={`nav-item${history.location.pathname === '/user/profile' ? ' selected' : ''}`} tabIndex="0" role="button" onClick={() => history.push('/user/profile')}>
            <div><img src={AchievementsMap} alt="הישגים" /></div>
            <div><span>הישגים</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentMainPage;
