/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Input as AntdInput, Form } from 'antd';

const Input = (props) => {
  if (props.placeholder) {
    return (
      <Form.Item required={props.required} tooltip={props.tooltip}>
        <span className="form-label">{props.placeholder}</span>
        <AntdInput {...props} />
      </Form.Item>
    );
  }
  return <AntdInput {...props} />;
};

export default Input;
