import Ahrayot from '../images/missions/bg/ahrayot-mission.png';
import AronKodesh from '../images/missions/bg/aron-kodesh1-mission.png';
import Bagrut from '../images/missions/bg/bagrut-mission.png';
import BeitKneset from '../images/missions/bg/beit-kneset1-mission.png';
import BrachotHanenim from '../images/missions/bg/brachot-hanenim-mission.png';
import Manhigot from '../images/missions/bg/manhigot-mission.png';
import Netina from '../images/missions/bg/netina-mission.png';
import Shma from '../images/missions/bg/shma-mission.png';
// TODO: need correct image
import TaamiMikra from '../images/missions/bg/taami-mikra-mission.jpg';
import TalitTfilin from '../images/missions/bg/talit-tfilin-mission.png';

const missioNameToBackground = {
  shma: Shma,
  'taami-mikra': TaamiMikra,
  'brachot-hanenim': BrachotHanenim,
  'aron-kodesh': AronKodesh,
  'talit-tfilin': TalitTfilin,
  'beit-kneset': BeitKneset,
  netina: Netina,
  bagrut: Bagrut,
  manhigot: Manhigot,
  ahrayot: Ahrayot,
};

export default missioNameToBackground;
