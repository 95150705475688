import React, {
  useContext, useEffect, useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import BackButton from '../images/back-button.png';
import SelectField from '../components/forms/selectField';
import UserContext from '../contexts/userContext';
import './ParashaContainer.scss';

const StudentPageHeader = ({ haftara }) => {
  const history = useHistory();

  return (
    <div className="student-header">
      <div>
        <div
          role="button"
          tabIndex={0}
          onClick={(ev) => { ev.preventDefault(); history.push('/'); }}
          className="back-button"
        >
          <img src={BackButton} alt="back button" />
        </div>
        <span>קריאה בתורה</span>
      </div>
    </div>
  );
};

const StudentSubheader = ({ children }) => (
  <div className="student-sub-header">
    <div className="student-sub-header-darkbrown">
      <div className="student-sub-header-brown">
        <div className="student-sub-header-yellow">
          {children}
        </div>
      </div>
    </div>
  </div>
);

const StudentPage = ({ children, haftara }) => (
  <div className="student-page">
    <StudentPageHeader haftara={haftara} />
    <div>
      {children}
    </div>
  </div>
);

const nusachToName = {
  0: 'morocco',
  1: 'ashkenazi-chabad',
  2: 'jerusalem',
};

const ParashaContainer = ({ haftara }) => {
  const user = useContext(UserContext);
  const [parashot, setParashot] = useState(null);
  const [selectedParashaIdx, setSelectedParashaIdx] = useState(0);

  useEffect(() => {
    if (!user) return;
    const newParashot = [{
      text: user.parashot[0].name,
      value: user.parashot[0].ben13Id,
      hagSubtitle: user.parashot[0].hagSubtitle,
      ben13Id: user.parashot[0].ben13Id,
    }];

    if (user.parashot[1]) {
      newParashot.push({
        text: user.parashot[1]?.name,
        value: user.parashot[1]?.ben13Id,
        hagSubtitle: user.parashot[1]?.hagSubtitle,
        ben13Id: user.parashot[1]?.ben13Id,
      });
    }
    setParashot(newParashot);
  }, [user]);

  if (!user) return '';

  const bookId = haftara ? 8 : user.parashot?.[selectedParashaIdx].bookId;
  const parashaId = haftara ? parseInt(user?.haftara, 10) + 71
    : user.parashot?.[selectedParashaIdx].ben13Id;
  const hagSubtitle = haftara ? user?.haftaraFull.hagSubtitle
    : user.parashot?.[selectedParashaIdx].hagSubtitle;

  if (haftara && !user.haftaraFull) {
    return (
      <StudentPage haftara={haftara}>
        <div>
          <span>יש לפנות למדריך לבחירת הפטרה</span>
        </div>
      </StudentPage>
    );
  }

  return (
    <StudentPage haftara={haftara}>
      <div className="parasha-subheader">
        <StudentSubheader>
          <div className="parasha">
            {haftara ? (parashot && (
              <>
                <span>
                  <b>הפטרת :&nbsp;</b>
                  {user.haftaraFull.name}
                </span>
              </>
            )) : (
              <>
                <span>פרשה: </span>
                {parashot && (
                  <SelectField
                    onChange={(ev) => setSelectedParashaIdx(ev.target.selectedIndex - 1)}
                    placeholder="בחר פרשה"
                    options={parashot}
                    selectedIndex={selectedParashaIdx}
                    value={user.parashot?.[selectedParashaIdx].ben13Id}
                  />
                )}
              </>
            )}
          </div>
          {!haftara ? (
            <div className="haftara">
              <span>הפטרה: </span>
              <Link to="/haftara"><span>{user.haftaraFull?.name}</span></Link>
            </div>
          ) : (
            <div className="haftara">
              <Link to="/parasha"><span>קריאת הפרשה</span></Link>
            </div>
          )}
        </StudentSubheader>
      </div>
      <div className="iframe-wrapper">
        {user && <iframe seamless style={{ overflow: 'hidden' }} src={`https://www.ben13.co.il/ben13/?book=${bookId}&hagSubtitle=${hagSubtitle}&parasha=${parashaId}&nosach=${nusachToName[user.nusach]}&thin=true`} title="ben 13" />}
      </div>
    </StudentPage>
  );
};

export default ParashaContainer;
