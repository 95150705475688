import Avatar1 from '../images/avatars/avatar1.png';
import Avatar2 from '../images/avatars/avatar2.svg';
import Avatar3 from '../images/avatars/avatar3.png';
import Avatar4 from '../images/avatars/avatar4.svg';

const avatars = {
  avatar1: {
    image: Avatar1,
    id: 'avatar1',
  },
  avatar2: {
    image: Avatar2,
    id: 'avatar2',
  },
  avatar3: {
    image: Avatar3,
    id: 'avatar3',
  },
  avatar4: {
    image: Avatar4,
    id: 'avatar4',
  },
};

export default avatars;
