import * as React from 'react';
import { Modal } from 'antd';
import ModalBodyBG from '../images/modal/body.svg';
import TitleModalBodyBG from '../images/modal/title-modal-body.svg';
import ModalHeaderBG from '../images/modal/header-bg.png';
import ModalTitleBG from '../images/modal/title-bg.svg';
import CloseIcon from '../images/icons/close.png';
import './Modal.scss';

const ChabadModal = (props) => (
  <Modal
    footer={[]}
    visible={props.open}
    modalRender={() => (
      <div className={`chabad-modal ${props.type || ''}`}>
        <div>
          <div className="chabad-modal-center chabad-modal-close">
            <button
              className="naive-button"
              type="button"
              onClick={props.onCancel}
            >
              <img src={CloseIcon} alt="סגירה" />
            </button>
          </div>
          {!props.hideHeaderImgBackground && <div className="chabad-modal-center chabad-modal-header"><img src={ModalHeaderBG} alt="" /></div>}
          <div className="chabad-modal-center chabad-modal-header-title"><img src={ModalTitleBG} alt="" /></div>
          <div className="chabad-modal-title">

            <div>
              <span className="modal-headline-subtext">
                {props.titleSubText}
              </span>
            </div>
            <span className="modal-headline">
              {props.title}
            </span>
          </div>
        </div>
        <div className="chabad-modal-center chabad-modal-body">
          <img src={props.bodyImgBackground || ModalBodyBG} alt="" />
          <div className="chabad-modal-center chabad-modal-body-content">
            {props.body}
          </div>
        </div>
        <div className="chabad-modal-footer" />
      </div>
    )}
  />
);

export const TitleModal = (props) => (
  <ChabadModal
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    type="title-modal"
    hideHeaderImgBackground
    bodyImgBackground={TitleModalBodyBG}
  />
);

export default ChabadModal;
