import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import './GuideScreenContainer.scss';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import UserItemList from '../components/UserItemList';
import SearchImg from '../images/Search.png';
import PlusButton from '../svgs/plus-button.svg';
import Page from './Page';
import UserContext from '../contexts/userContext';
import { daysToBarMitzva } from '../utils/dates';
import DownloadButton from '../svgs/download-button.svg';
import statusEnum from '../enums/status.enum';
import { arrayToCSV, downloadBlob, mapStudentToCSV } from '../utils/csv';

const GuideScreen = () => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [origUsers, setOrigUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState(statusEnum.active);
  const [searchFilter, setSearchFilter] = useState('');

  const user = React.useContext(UserContext);

  const getUsers = () => {
    const fetchUsers = async () => {
      if (!user) return;
      const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
      const usersRes = await axios.get(`/api/students?guideId=${queryString.guideId || user.userId}`);
      setUsers(usersRes.data?.filter((u) => u.status === statusEnum.active));
      setOrigUsers(usersRes.data);
    };
    fetchUsers();
  };
  useEffect(getUsers, [user, history.location.search]);

  const filters = () => {
    setUsers(origUsers?.filter((u) => {
      const fullName = `${u.name} ${u.lastName}`;
      return (statusFilter === null || u.status === statusFilter)
      && fullName.indexOf(searchFilter) !== -1;
    }));
  };
  useEffect(filters, [statusFilter, origUsers, searchFilter]);

  const onTabChange = (activeKey) => {
    if (activeKey === '1') setStatusFilter(statusEnum.active); // active
    if (activeKey === '2') setStatusFilter(statusEnum.notActive); // not active
    if (activeKey === '3') setStatusFilter(null);
  };

  const gotoAddStudent = () => {
    const path = `/admin/add-student${ history.location.search}`;
    history.push(path);
  };

  const onSearchChange = (ev) => {
    setSearchFilter(ev.target.value);
  };

  const removeStudent = async (userId) => {
    setOrigUsers(origUsers?.filter((u) => u.userId !== userId));
    setUsers(users?.filter((u) => u.userId !== userId));
    await axios.delete(`/api/students/${userId}`);
  };
  const downloadData = () => {
    const csv = arrayToCSV(users.map(mapStudentToCSV));
    downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;');
  };

  return (
    <Page
      title={user?.name}
      onTabChange={onTabChange}
      tab1={`תלמידים פעילים ${origUsers.filter((u) => u.status === statusEnum.active).length}`}
      tab2={`לא פעילים ${origUsers.filter((u) => u.status === statusEnum.notActive).length}`}
      tab3={`כל התלמידים ${origUsers.length}`}
    >
      <div className="search-wrapper">
        <div className="search-bar">
          <img
            src={SearchImg}
            className="search-img"
            alt="search-icon-img"
          />
          <input onChange={onSearchChange} placeholder="חפש שם" className="search-input-box" />
        </div>
      </div>
      <div className="students-list">
        {users.map((u) => (
          <UserItemList
            // showEditBtn={u.status === statusEnum.notActive
            //   ? (user.isManager || !u.activationDate) : true}
            showEditBtn
            onDeleteClick={removeStudent}
            onClick={(ev, userId) => history.push(`/admin/manage-student/${userId}${history.location.search}`)}
            onEditClick={(userId) => history.push(`/admin/edit-student/${userId}${history.location.search}`)}
            key={u.userId}
            userId={u.userId}
            name={`${u.name } ${u.lastName || ''}`}
            count={daysToBarMitzva(u.birthDate, u.afterSunset)}
            countText="יום לבר מצווה"
          />
        ))}
      </div>
      <div className="actions-area">
        <button type="button" className="action-button" name="list" onClick={gotoAddStudent}>
          <img src={PlusButton} className="sign" alt="upload" />
        </button>
        <button type="button" className="action-button" name="list" onClick={downloadData}>
          <img src={DownloadButton} className="download-button" alt="download" width={30} />
        </button>
      </div>
    </Page>
  );
};

export default GuideScreen;
