import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Radio, Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import './MissionContainer.scss';
import StudentMainPage from '../layout/StudentMainPage';
import BackButton from '../images/back-button.png';
import PlayIcon from '../images/icons/play.png';
import PauseIcon from '../images/icons/pause.png';
import TorahIcon from '../images/icons/torah.png';
import VIcon from '../images/icons/v-icon.png';
import PresentIcon from '../images/icons/present.png';
// import EmptyIcon from '../images/icons/empty.png';
import missioNameToBackground from '../enums/missionNameToBackground';
import ChabadModal from '../components/Modal';
import Button from '../foundation/Button';
import UserContext from '../contexts/userContext';
import Loader from '../components/Loader';
import studentMissionState from '../enums/studentMissionState';
import UserRanksModals from '../components/UserRanksModals';

const MissionContainer = () => {
  const [form] = Form.useForm();
  const audioEl = useRef();

  const [isPlaying, setIsPlaying] = useState(false);
  const [mission, setMission] = useState();
  const [guideBonus, setGuideBonus] = useState();
  const [isBonusDone, setBonusDone] = useState();
  const [taskPoints, setTaskPoints] = useState();
  const [loading, setLoading] = useState(false);
  const [isMissionModalOpen, setIsMissionModalOpen] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [userToMission, setUserToMission] = useState(null);
  const [isMissionPointsModalOpen, setIsMissionPointsModalOpen] = useState(false);
  const history = useHistory();

  const { missionId } = useParams();
  const user = useContext(UserContext);

  // Update mission with visited state
  const playAudio = React.useCallback(async () => {
    audioEl.current?.play();
    setIsPlaying(true);
  }, [audioEl]);
  const pauseAudio = React.useCallback(async () => {
    audioEl.current?.pause();
    setIsPlaying(false);
  }, [audioEl]);
  const toggleAudio = React.useCallback(async () => {
    if (audioEl.current.paused) {
      await playAudio();
    } else {
      await pauseAudio();
    }
  }, [playAudio, pauseAudio]);

  const onFirstVisit = React.useCallback(async () => {
    await Axios.put(`/api/users/${userToMission.userId}/missions/${userToMission.missionId}`, {
      userId: userToMission.userId,
      missionId: userToMission.missionId,
      state: studentMissionState.visited,
    });
    playAudio();
  }, [userToMission, playAudio]);

  useEffect(() => {
    if (userToMission && userToMission.state !== studentMissionState.visited) {
      onFirstVisit();
    }
  }, [userToMission, onFirstVisit]);

  useEffect(() => {
    const getMission = async () => {
      const missionRes = await Axios.get(`/api/missions/${missionId}`);
      const tasksRes = await Axios.get(`/api/guides/${user.guideId}/tasks`);
      const bonunesRes = await Axios.get(`/api/guides/${user.guideId}/bonuses`);
      setMission({
        ...missionRes.data,
        tasks: tasksRes.data.filter((t) => t.missionId === missionRes.data.missionId).map((t) => ({
          ...t,
          prompt: t.prompt || t.taskPrompt,
          solution: t.solution || t.taskSolution,
        })),
      });

      const userToMissionRes = await Axios.get(`/api/users/${user.userId}/missions/${missionRes.data.missionId}`);
      setUserToMission(userToMissionRes.data);

      setGuideBonus(bonunesRes.data.filter(
        (b) => b.missionId === missionRes.data.missionId,
      ).map((b) => ({
        ...b,
        prompt: b.prompt || b.bonusPrompt,
        promptWeekly: b.promptWeekly || b.bonusPromptWeekly,
      }))?.[0]);

      if (userToMissionRes.data.lastBonusAnswerDate) {
        // lastBonusAnswerDate is today
        const lastBonusAnswerDate = new Date(userToMissionRes.data.lastBonusAnswerDate);
        const today = new Date();
        if (
          lastBonusAnswerDate.getDate() === today.getDate()
          && lastBonusAnswerDate.getMonth() === today.getMonth()
          && lastBonusAnswerDate.getFullYear() === today.getFullYear()
        ) {
          setBonusDone(true);
        }
      }
    };
    if (missionId && user) getMission();
  }, [missionId, user]);

  const backClick = () => {
    history.goBack();
  };

  const showBonusModal = React.useCallback(() => {
    if (isBonusDone) return;
    setIsBonusModalOpen(true);
  }, [isBonusDone]);
  const showMissionModal = React.useCallback(() => {
    if (userToMission.taskPoints !== null) return;
    setIsMissionModalOpen(true);
  }, [userToMission]);
  const closeMissionModal = React.useCallback(() => {
    setIsMissionModalOpen(false);
  }, []);

  const bonusCompletedClick = React.useCallback(async () => {
    setLoading(true);

    const bonusPoints = (userToMission.bonusPoints || 0) + guideBonus.bonusPoints;

    await Axios.put(`/api/users/${user.userId}/missions/${missionId}`, {
      userId: user.userId,
      missionId,
      bonusPoints,
      lastBonusAnswerDate: new Date(),
    });

    window.location.reload();
  }, [user, userToMission, guideBonus, missionId]);

  const questionsCompleted = () => {
    setIsMissionPointsModalOpen(false);
    window.location.reload();
  };

  const onCompleteMission = async (values) => {
    const points = Object.values(values).reduce((prev, currValue, currentIndex) => {
      if (mission.tasks[currentIndex].solution === currValue) {
        return prev + 5;
      }
      return prev;
    }, 0);

    setTaskPoints(points);
    setLoading(true);

    await Axios.put(`/api/users/${user.userId}/missions/${mission.missionId}`, {
      userId: user.userId,
      missionId,
      taskPoints: points,
    });

    setLoading(false);
    setIsMissionModalOpen(false);
    setIsMissionPointsModalOpen(true);
  };

  if (!mission || !userToMission) return <Loader />;

  return (
    <StudentMainPage>
      <UserRanksModals userStars={user.stars} />
      {loading && <Loader />}
      <div className="mission-container">
        <div className="mission-item">
          <>
            {guideBonus && (
              <ChabadModal
                open={isBonusModalOpen}
                onCancel={() => setIsBonusModalOpen(false)}
                title="משימת בונוס"
                titleSubText={mission.txt}
                body={(
                  <>
                    <div className="mission-modal-body">
                      <div className="congrats">
                        <div className="maccabi-text medium-text bonus-text">
                          <br />
                          <div><span><b><u>משימה יומית</u></b></span></div>
                          {guideBonus.prompt}
                          <br />
                          <br />
                          <div><span><b><u>משימה שבועית</u></b></span></div>
                          {guideBonus.promptWeekly}
                        </div>
                        <span className="maccabi-text">
                          <div className="avatar-header-stars-wrapper">
                            <div className="avatar-stars" />
                            <span>
                              {guideBonus.bonusPoints}
                            </span>
                          </div>
                        </span>
                      </div>
                      <div className="mission-modal-footer">
                        <Button
                          fullWidth
                          onClick={bonusCompletedClick}
                        >
                          <span>ביצעתי</span>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              />
            )}
            <ChabadModal
              open={isMissionPointsModalOpen}
              onCancel={questionsCompleted}
              title="המשימה הושלמה"
              titleSubText={mission.txt}
              body={(
                <>
                  <div className="mission-modal-body">
                    <div className="congrats">
                      {taskPoints > 0 && (
                        <span className="complete-subtitle">
                          ברכותינו
                        </span>
                      )}
                      <br />
                      {taskPoints === 0 ? <span>לצערינו לא זכית הפעם.</span> : (
                        <>
                          <span>
                            זכית ב -
                          </span>
                          <br />
                          <br />
                          <span>
                            <div className="avatar-header-stars-wrapper">
                              <div className="avatar-stars" />
                              <span>{taskPoints}</span>
                            </div>
                          </span>
                        </>
                      )}
                    </div>
                    <div className="mission-modal-footer">
                      <Button
                        fullWidth
                        onClick={questionsCompleted}
                      >
                        <span>חזרה לאי</span>
                      </Button>
                    </div>
                  </div>
                </>
              )}
            />
            <ChabadModal
              open={isMissionModalOpen}
              onCancel={closeMissionModal}
              title="שאלון"
              titleSubText={mission.txt}
              body={(
                <>
                  <Form form={form} className="mission-modal-body" name="mission-answers" onFinish={onCompleteMission}>
                    {mission.tasks.map((taskItem) => (
                      <div key={taskItem.taskId}>
                        <div>
                          {taskItem.prompt}
                        </div>
                        <div className="select-answer-wrapper">
                          <Form.Item name={taskItem.taskId} rules={[{ required: true, message: 'שדה חובה' }]}>
                            <Radio.Group size="small" buttonStyle="solid">
                              <Radio.Button value="true">נכון</Radio.Button>
                              <Radio.Button value="false">לא נכון</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <div align="center" className="mission-modal-footer">
                      <Button
                        htmlType="submit"
                        fullWidth
                      >
                        <span>סיימתי!</span>
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            />
            <br />
            <img className="mission-bg" alt={mission.name} src={missioNameToBackground[mission.name]} />
            <div className="page-subtitle">
              <span>{mission?.txt}</span>
              <div className="subtitle-back-button">
                <img
                  src={BackButton}
                  alt="back"
                  onClick={backClick}
                  aria-hidden="true"
                />
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls src={`/tracks/${mission.name}.mp3`} type="audio/mp3" hidden ref={audioEl}>
              Your browser does not support the audio tag.
            </audio>
            <div className="mission-footer">
              <button className="support-check naive-button" type="button" onClick={showMissionModal}>
                <img src={TorahIcon} alt="משימות" />
                {userToMission.taskPoints !== null && <span><img src={VIcon} alt="הסתיים" /></span>}
              </button>
              <button className="naive-button" type="button" onClick={toggleAudio}>
                {isPlaying ? <img src={PauseIcon} alt="השהה ג'ינגל" /> : <img src={PlayIcon} alt="נגן ג'ינגל" />}
              </button>
              <button className="support-check naive-button" type="button" onClick={showBonusModal}>
                {isBonusDone && <span><img src={VIcon} alt="הסתיים" /></span>}
                <img src={PresentIcon} alt="משימת בונוס" />
              </button>
            </div>
          </>
        </div>
      </div>
    </StudentMainPage>
  );
};

export default MissionContainer;
