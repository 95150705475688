import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ManageStudentContainer.scss';
import Axios from 'axios';
import Page from './Page';
import RowBoxItemList from '../components/RowBoxItemList';
import missioNameToImage from '../enums/missionNameToImage';
import Button from '../foundation/Button';
import studentMissionState from '../enums/studentMissionState';

function ManageStudent() {
  const { studentId } = useParams();

  const [student, setStudent] = useState({ isStudent: true });
  const [studentMissions, setStudentMissions] = useState([]);
  const [isAddingMission, setIsAddingMission] = useState(false);

  useEffect(() => {
    const getStudent = async () => {
      const studentRes = await Axios.get(`/api/users/${studentId}`);
      setStudent(studentRes.data);
      const studentMissionsRes = await Axios.get(`/api/users/${studentId}/missions`);
      setStudentMissions(studentMissionsRes.data);
    };
    if (studentId) getStudent();
  }, [studentId]);

  const [missions, setMissions] = useState([]);

  useEffect(() => {
    const getMissions = async () => {
      const missionsRes = await Axios.get('/api/missions');
      setMissions(missionsRes.data);
    };
    getMissions();
  }, []);

  const onTabChange = () => {};

  const onAddMissionToUser = async (studentMission) => {
    setIsAddingMission(true);
    const res = await Axios.post(`/api/users/${student.userId}/missions`, {
      userId: student.userId,
      missionId: studentMission.missionId,
    });
    setStudentMissions([...studentMissions, res.data]);
    setIsAddingMission(false);
  };

  const onLockMission = async (studentMission) => {
    await Axios.put(`/api/users/${student.userId}/missions/${studentMission.missionId}`, {
      userId: student.userId,
      missionId: studentMission.missionId,
      state: studentMissionState.locked,
    });
    setStudentMissions(studentMissions.map((s) => (s.missionId === studentMission.missionId ? {
      ...studentMission,
      state: studentMissionState.locked,
    } : s)));
  };

  const onOpenMission = async (studentMission) => {
    await Axios.put(`/api/users/${student.userId}/missions/${studentMission.missionId}`, {
      userId: student.userId,
      missionId: studentMission.missionId,
      state: studentMissionState.open,
    });
    setStudentMissions(studentMissions.map((s) => (s.missionId === studentMission.missionId ? {
      ...studentMission,
      state: studentMissionState.open,
    } : s)));
  };

  const onRemoveMissionFromUser = async (studentMission) => {
    await Axios.delete(`/api/users/${student.userId}/missions/${studentMission.missionId}`, {
      userId: student.userId,
      missionId: studentMission.missionId,
    });
    const newStudentMissions = [...studentMissions];
    newStudentMissions.splice(studentMissions.indexOf(studentMission), 1);
    setStudentMissions(newStudentMissions);
  };

  const renderMission = (m) => {
    const studentMission = studentMissions.find((st) => st.missionId === m.missionId);
    return (
      <RowBoxItemList key={m.missionId}>
        <div>
          <img height={50} alt={m.txt} src={missioNameToImage[m.name]} />
        </div>
        <div>
          <span>{m.txt}</span>
          <br />
          <div style={{ fontSize: '10px' }}>
            (
            {m.displayName}
            )
          </div>
        </div>
        <div>
          {!studentMission
            ? (
              <Button small disabled={isAddingMission} onClick={() => onAddMissionToUser(m)}>
                הצג משימה
              </Button>
            )
            : (
              <div className="mission-actions-wrapper">
                {(!studentMission.state || studentMission.state === studentMissionState.locked)
                  ? <Button small onClick={() => onOpenMission(studentMission)}>פתיחה</Button>
                  : <Button small onClick={() => onLockMission(studentMission)}>נעילה</Button>}
                <Button
                  red
                  small
                  onClick={() => onRemoveMissionFromUser(studentMission)}
                >
                  הסתר משימה
                </Button>
              </div>
            )}
        </div>
      </RowBoxItemList>
    );
  };

  return (
    <Page
      title={`${student?.name} ${student?.lastName}`}
      onTabChange={onTabChange}
      showBackBtn
      tab1="משימות"
    >
      <div className="manage-student-container">
        <br />
        <div className="row-box-item-list-student-manager">
          {missions?.map(renderMission)}
        </div>
      </div>
    </Page>

  );
}

export default ManageStudent;
