import * as React from 'react';
import { useState } from 'react';
import { TitleModal } from './Modal';
import { getUserRank } from './UserRank';
import './UserRanksModals.scss';
import './Confetti.scss';
import Button from '../foundation/Button';
import ConfettiGIF from '../images/confetti.gif';

const PageConfetti = () => (
  <div className="page-confetti">
    <img src={ConfettiGIF} alt="confetti" />
  </div>
);

const UserRanksModals = ({ userStars }) => {
  const [showModal, setShowModal] = useState(false);
  const userRank = getUserRank(userStars);

  React.useEffect(() => {
    try {
      const sessionStorageStars = parseInt(sessionStorage.getItem('stars'), 10);
      if (sessionStorageStars !== userStars) {
        const lastRank = getUserRank(sessionStorageStars);
        if (lastRank.name !== userRank.name) {
          setShowModal(true);
          sessionStorage.setItem('stars', userStars);
        }
      }
    } catch (error) {
      // first time user enters in the current session
      sessionStorage.setItem('stars', userStars);
    }
  }, [userStars, userRank?.name]);

  const closeModal = () => {
    setShowModal(false);
  };

  if (!userRank) return null;

  return (
    <div>
      <TitleModal
        title="שלב חדש!"
        open={showModal}
        onCancel={closeModal}
        body={(
          <div className="new-rank-congrats">
            <PageConfetti />
            <div>
              <div>
                <span className="xxlarge-text">{userRank.displayName}</span>
              </div>
              <div className="rank-image-wrapper">
                <img width={135} src={userRank.image} alt={userRank.displayName} />
              </div>
              <div>
                <div>
                  <span>ברכות!</span>
                </div>
                <div className="sub-text-wrapper">
                  <span className="medium-text maccabi-text-light">
                    אנחנו מתרגשים, צברת נקודות וזכית לעבור לשלב חדש:
                    {' '}
                    {userRank.displayName}
                    .
                  </span>
                </div>
              </div>
            </div>
            <Button
              fullWidth
              onClick={closeModal}
            >
              <span className="xlarge-text">חזרה</span>
            </Button>
          </div>
        )}
      />
    </div>
  );
};

export default React.memo(UserRanksModals);
