/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './RowBoxItemList.scss';

const RowBoxItemList = (props) => (
  <div
    {...props}
    className="row-box-item-list"
  >
    {props.children}
  </div>
);

export default RowBoxItemList;
