import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import UserContext from '../contexts/userContext';
import './MapContainer.scss';
import StudentMainPage from '../layout/StudentMainPage';

import missioNameToImage from '../enums/missionNameToImage';
import studentMissionState from '../enums/studentMissionState';

const MapContainer = () => {
  // const [missions, setMissions] = useState([]);
  const [studentMissions, setStudentMissions] = useState([]);
  const user = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    const getStudent = async () => {
      const studentMissionsRes = await Axios.get(`/api/users/${user.userId}/missions`);
      setStudentMissions(
        studentMissionsRes.data?.sort((a, b) => (a.missionId > b.missionId ? 1 : -1)),
      );
    };
    if (user) getStudent();
  }, [user]);

  const gotoMission = async (studentMission) => {
    history.push(`/missions/${studentMission.missionId}`);
  };

  const renderMissionIcon = (studentMission) => {
    if (!studentMission.state || studentMission.state === studentMissionState.locked) {
      return <div className="locked-mission-icon" />;
    }
    if (studentMission.state === studentMissionState.open) {
      return <div className="enter-mission-icon" />;
    }

    return <div className="visited-mission-icon" />;
  };

  return (
    <StudentMainPage>
      <div className="map-container">
        <div className="mission-items">
          <br />
          {studentMissions?.map((studentMission) => (
            <div
              tabIndex={0}
              role="button"
              onClick={() => {
                if (!studentMission.state
                   || studentMission.state === studentMissionState.locked) return;
                gotoMission(studentMission);
              }}
              key={studentMission.mission.txt}
              className="mission-item"
            >
              <div>
                {renderMissionIcon(studentMission)}
                <img
                  alt={studentMission.mission.txt}
                  src={missioNameToImage[studentMission.mission.name]}
                />
              </div>
              <div className="mission-item-text">
                <span>{studentMission.mission.txt}</span>
              </div>
              <br />
            </div>
          ))}
        </div>
      </div>
    </StudentMainPage>
  );
};

export default MapContainer;
