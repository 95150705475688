import { HDate, Locale, HebrewCalendar } from '@hebcal/core';

// eslint-disable-next-line react-hooks/rules-of-hooks
Locale.useLocale('he');

export const isShabbatFallsBetween = (startDate, endDate) => {
  const start = new HDate(startDate);
  const end = new HDate(endDate);

  let currentDate = start;
  while (currentDate <= end) {
    if (currentDate.getDay() === 6) {
      return true;
    }
    currentDate = currentDate.next();
  }
  return false;
};

export const getHebBarMitzvaDate = (birthDate, afterSunset) => {
  const bDate = new Date(birthDate);
  if (afterSunset) bDate.setDate(bDate.getDate() + 1);
  const hebDate = new HDate(bDate);
  const barMitzvaYear = hebDate.getFullYear() + 13;
  return HebrewCalendar.getBirthdayOrAnniversary(barMitzvaYear, bDate);
};

export const getHebBirthDate = (birthDate, afterSunset) => {
  const curDate = new Date(birthDate);
  if (afterSunset) curDate.setDate(curDate.getDate() + 1);
  const hebDate = new HDate(curDate);
  return hebDate.renderGematriya();
};

export const getHebBarMitzvaStr = (birthDate, afterSunset) => {
  const curDate = getHebBarMitzvaDate(birthDate, afterSunset);
  return curDate.renderGematriya();
};

export const daysToBarMitzva = (birthDate, afterSunset) => {
  const barMitzvaDate = getHebBarMitzvaDate(birthDate, afterSunset);
  const currentDate = new HDate(new Date());

  return Math.floor(
    barMitzvaDate.deltaDays(currentDate),
  );
};
