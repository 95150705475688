import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import './ManagersContainer.scss';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import UserItemList from '../components/UserItemList';
import SearchImg from '../images/Search.png';
import PlusButton from '../svgs/plus-button.svg';
import DownloadButton from '../svgs/download-button.svg';
import Page from './Page';
import UserContext from '../contexts/userContext';
import { daysToBarMitzva } from '../utils/dates';
import { arrayToCSV, downloadBlob, mapStudentToCSV } from '../utils/csv';

const ManagersContainer = () => {
  const history = useHistory();

  const origGuides = useRef(null);
  const [guides, setGuides] = useState([]);

  const origStudents = useRef(null);
  const [students, setStudents] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [removedUsers, setRemovedUsers] = useState([]);
  const refSearchInput = useRef(null);

  let defaultTab;
  if (history.location.pathname === '/admin/manager') defaultTab = '1';
  else if (history.location.pathname === '/admin/manager/students') defaultTab = '2';
  else defaultTab = '3';

  const [activeKey, setActiveKey] = useState(defaultTab);

  useEffect(() => {
    const fetchGuides = async () => {
      const guidesRes = await axios.get('/api/guides');
      origGuides.current = guidesRes.data;
      setGuides(guidesRes.data);
    };
    fetchGuides();

    const fetchStudents = async () => {
      const studentsRes = await axios.get('/api/students');
      origStudents.current = studentsRes.data;
      setStudents(studentsRes.data);
    };
    fetchStudents();
  }, []);

  const onTabChange = (actKey) => {
    setActiveKey(actKey);
    if (actKey === '1') history.push('/admin/manager');
    if (actKey === '2') history.push('/admin/manager/students');
    if (actKey === '3') history.push('/admin/manager/removed-users');
  };

  const gotoGuide = (ev, guideId) => {
    history.push(`/admin/guide?guideId=${guideId}`);
  };

  const gotoTeacher = () => {
    const path = '/admin/manager/add-guide';
    history.push(path);
  };

  const downloadData = () => {
    let csv;
    if (activeKey === '1') csv = arrayToCSV(guides.map(mapStudentToCSV));
    if (activeKey === '2') csv = arrayToCSV(students.map(mapStudentToCSV));
    if (activeKey === '3') csv = arrayToCSV(removedUsers.map(mapStudentToCSV));
    downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;');
  };

  const user = React.useContext(UserContext);

  const onSearchChange = (ev) => {
    const val = ev.target.value;
    setSearchKey(val);
    if (activeKey === '3') {
      if (val?.length > 2) {
        fetchRemoveUsers(val);
      }
    } else {
      setGuides(origGuides.current?.filter((g) => g.name.indexOf(val) !== -1));
      setStudents(origStudents.current?.filter((s) => s.name.indexOf(val) !== -1));
    }
  };

  const fetchRemoveUsers = async (containsKey) => {
    const getUsersRes = await axios.get(`/api/users?deleted=true&contains=${containsKey}&includeGuide=true`);
    setRemovedUsers(getUsersRes.data);
  };

  const removeStudent = async (userId) => {
    setStudents(students?.filter((u) => u.userId !== userId));
    origStudents.current = origStudents?.current.filter((u) => u.userId !== userId);
    await axios.delete(`/api/students/${userId}`);
  };

  const restoreUser = async (userId) => {
    setRemovedUsers(removedUsers?.filter((u) => u.userId !== userId));
    await axios.post(`/api/users/${userId}/restore`);
  };

  const removeGuide = async (userId) => {
    setGuides(guides?.filter((u) => u.userId !== userId));
    origGuides.current = origGuides?.current.filter((u) => u.userId !== userId);
    await axios.delete(`/api/guides/${userId}`);
  };

  return (
    <Page
      title={user?.name}
      defaultTabKey={defaultTab}
      onTabChange={onTabChange}
      tab1={`מדריכים ${guides?.length || ''}`}
      tab2={`תלמידים ${students?.length || ''}`}
      tab3="מוסרים"
    >
      <div className="search-wrapper">
        <div className="search-bar">
          <img
            src={SearchImg}
            className="search-img"
            alt="search-icon-img"
          />
          <input ref={refSearchInput} onChange={onSearchChange} placeholder="חפש שם" className="search-input-box" />
        </div>
      </div>

      <Switch>
        <Route path="/admin/manager/students">
          <div className="students-list">
            {students.map((gItem) => (
              <UserItemList
                showEditBtn
                onDeleteClick={removeStudent}
                onEditClick={(userId) => history.push(`/admin/edit-student/${userId}`)}
                key={gItem.userId}
                userId={gItem.userId}
                status={gItem.status}
                name={`${gItem.name } ${gItem.lastName || ''}`}
                count={daysToBarMitzva(gItem.birthDate, gItem.afterSunset)}
                countText="יום לבר מצווה"
              />
            ))}
          </div>
        </Route>
        <Route path="/admin/manager/removed-users">
          <div className="students-list">
            {removedUsers.length === 0 && (
              <div>
                <br />
                {searchKey?.length > 2 ? 'לא נמצאו תוצאות.' : 'הקלד שם לחיפוש, יש להזין לפחות 3 תווים.'}
              </div>
            )}
            {removedUsers.map((gItem) => (
              <UserItemList
                showEditBtn
                onEditClick={(userId) => history.push(`/admin/edit-student/${userId}`)}
                onRestoreClick={restoreUser}
                key={gItem.userId}
                userId={gItem.userId}
                status={gItem.status}
                name={`${gItem.name } ${gItem.lastName || ''}`}
              />
            ))}
          </div>
        </Route>
        <Route path="/">
          <div className="guides-list">
            {guides.map((gItem) => (
              <UserItemList
                showEditBtn
                onDeleteClick={removeGuide}
                onEditClick={(userId) => history.push(`/admin/manager/edit-guide/${userId}`)}
                onClick={gotoGuide}
                key={gItem.userId}
                userId={gItem.userId}
                status={gItem.status}
                name={`${gItem.name} ${gItem.lastName || ''}`}
                count={gItem.totalStudents}
                countText="תלמידים"
              />
            ))}
          </div>
        </Route>
      </Switch>
      <div className="actions-area">
        <button type="button" className="action-button" name="list" onClick={gotoTeacher}>
          <img src={PlusButton} className="sign" alt="upload" />
        </button>
        <button type="button" className="action-button" name="list" onClick={downloadData}>
          <img src={DownloadButton} className="download-button" alt="download" />
        </button>
      </div>
    </Page>
  );
};

export default ManagersContainer;
