import React from 'react';
import Rect from '../images/logo.png';

function Logo(props) {
  return (
    <img
      src={Rect}
      className={props.logo_css}
      alt="שבט 13"
      title="שבט 13"
    />
  );
}

export default Logo;
