import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './AddGuideContainer.scss';
import axios from 'axios';
import Page from './Page';
import Input from '../foundation/Input';
import FooterWithButton from '../components/footerWithButton';
import SelectField from '../components/forms/selectField';
import statusEnum from '../enums/status.enum';

const selectActiveStatusOpts = [{ value: statusEnum.active, text: 'פעיל' },
  { value: statusEnum.notActive, text: 'לא פעיל' }];

function AddGuideContainer() {
  const { guideId } = useParams();
  const [guide, setGuide] = useState({
    status: statusEnum.notActive,
  });

  useEffect(() => {
    const getGuide = async () => {
      const guideRes = await axios.get(`/api/users/${guideId}`);
      setGuide(guideRes.data);
    };
    if (guideId) getGuide();
  }, [guideId]);

  const history = useHistory();
  const [error, setError] = useState(null);

  function validate() {
    let err;
    if (!guide.name) err = 'יש להזין שם מדריך';
    else if (!guide.phoneNumber) err = 'יש להזין טלפון';
    else if (!guide.clientNumber) err = 'יש להזין מספר לקוח';

    // create fields validation
    if (!guideId) {
      if (!guide.password || !guide.passwordConfirm) err = 'יש להזין סיסמא';
      else if (guide.password !== guide.passwordConfirm) err = 'הסיסמאות אינן זהות';
    }

    if (err) {
      setError(err);
      return false;
    }
    return true;
  }

  function handleAddClick() {
    if (!validate()) return;
    const updateUser = async () => {
      try {
        await axios.post('/api/users', {
          ...guide,
          isGuide: true,
        });
        history.push('/admin/manager');
      } catch (err) {
        if (err.response.status === 409) setError('משתמש כבר קיים');
        else setError('אירעה שגיאה');
      }
    };
    updateUser();
  }

  function handleChange(event) {
    const { value } = event.target;

    switch (event.target.name) {
    case 'status':
      setGuide({
        ...guide,
        status: selectActiveStatusOpts[event.target.selectedIndex - 1].value,
      });
      break;
    default:
      setGuide({
        ...guide,
        [event.target.name]: value,
      });
      break;
    }
  }

  if (guideId && !guide.userId) return '';

  const defaultStatus = (guide ? (guide.status ?? statusEnum.notActive)
    : statusEnum.notActive).toString();

  return (
    <Page title={guide.userId ? 'עריכת מדריך' : 'הוספת מדריך'} showBackBtn>
      <div className="add-guide-container">
        <div className="add-guide-form">
          <div>
            <br />
            <Input
              className="input-field"
              name="name"
              type="text"
              autoComplete="off"
              onChange={handleChange}
              placeholder="שם מדריך"
              value={guide.name}
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="mosadName"
              type="text"
              autoComplete="off"
              onChange={handleChange}
              placeholder="שם מוסד"
              value={guide.mosadName}
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="phoneNumber"
              autoComplete="off"
              type="tel"
              onChange={handleChange}
              placeholder="טלפון מדריך"
              value={guide.phoneNumber}
            />
          </div>
          <div>
            <Input
              className="input-field"
              name="clientNumber"
              type="text"
              autoComplete="off"
              onChange={handleChange}
              placeholder="מס' לקוח"
              value={guide.clientNumber}
            />
          </div>
          <>
            <div>
              <Input
                className="input-field"
                name="password"
                type="password"
                autoComplete="new-password"
                onChange={handleChange}
                placeholder="סיסמא"
                value={guide.password}
              />
            </div>
            <div>
              <Input
                className="input-field"
                name="passwordConfirm"
                type="password"
                autoComplete="new-password"
                onChange={handleChange}
                placeholder="אימות סיסמא"
                value={guide.passwordConfirm}
              />
            </div>
          </>
          <SelectField
            text="סטאטוס"
            options={selectActiveStatusOpts}
            name="status"
            defaultValue={defaultStatus}
            placeholder="סטטוס"
            onChange={handleChange}
          />
          {error && (
            <div className="form-error">
              <span>{error}</span>
            </div>
          )}
        </div>
        <FooterWithButton btnText={guideId ? 'שמור' : 'הוסף'} btnClick={handleAddClick} />
      </div>
    </Page>
  );
}

export default AddGuideContainer;
