import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Input as AntdInput, Col, Form, Row,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './GuideScreenContainer.scss';
import Page from './Page';
import UserContext from '../contexts/userContext';
import './GuideManageTasks.scss';
import SelectField from '../components/forms/selectField';
import Button from '../foundation/Button';
import Loader from '../components/Loader';

const { TextArea } = AntdInput;

const solutionsMapping = {
  true: 'נכון',
  false: 'לא נכון',
};

const solutionOptions = [{
  value: 'true',
  text: 'נכון',
}, {
  value: 'false',
  text: 'לא נכון',
}];

const GuideManageTasks = () => {
  const user = React.useContext(UserContext);
  const [guideTasks, setGuideTasks] = useState();
  const [editTaskId, setEditTaskId] = useState();
  const [loading, setLoading] = useState();
  const [missions, setMissions] = useState();

  const getTasks = React.useCallback(async () => {
    if (!user) return;

    const guideTasksRes = await axios.get(`/api/guides/${user.userId}/tasks`);
    setGuideTasks(guideTasksRes.data);
  }, [user]);

  useEffect(() => {
    const getMissions = async () => {
      const missionsRes = await axios.get('/api/missions');
      const missionsData = {};
      missionsRes.data.forEach((mission) => {
        missionsData[mission.missionId] = mission;
      });
      setMissions(missionsData);
    };
    getMissions();
    getTasks();
  }, [getTasks]);

  const editTask = (taskId) => {
    setEditTaskId(taskId);
  };

  const submitTaskPrompt = async (values) => {
    setLoading(true);
    try {
      await axios.post(`/api/guides/tasks/${editTaskId}`, values);
      await getTasks();
    } finally {
      setLoading(false);
      setEditTaskId(null);
    }
  };

  if (!guideTasks || !missions) return null;

  return (
    <Page
      title={user?.name}
    >
      {loading && <Loader />}
      {guideTasks.map((guideTask) => (
        <div className="edit-task" key={guideTask.taskId}>
          <div className="edit-task-title">
            <div>
              <span>
                <b>משימה:</b>
                {' '}
                {missions[guideTask.missionId].txt}
              </span>
            </div>
            <div>
              {!editTaskId && (
                <EditOutlined onClick={() => editTask(guideTask.taskId)} style={{ fontSize: '1.2em' }} />
              )}
            </div>
          </div>
          {editTaskId !== guideTask.taskId ? (
            <div className="edit-task-content">
              <div>
                <b>שאלה:</b>
                {' '}
                {guideTask.prompt || guideTask.taskPrompt}
              </div>
              <div>
                <b>תשובה:</b>
                {' '}
                {solutionsMapping[guideTask.solution || guideTask.taskSolution]}
              </div>
            </div>
          ) : (
            <div>
              <br />
              <Form
                name="basic"
                onFinish={submitTaskPrompt}
                initialValues={{
                  prompt: guideTask.prompt || guideTask.taskPrompt,
                  solution: solutionOptions.find(
                    (option) => option.value === (guideTask.solution || guideTask.taskSolution),
                  ).value,
                }}
              >
                <Form.Item
                  wrapperCol
                  name="prompt"
                  rules={[{ required: true, message: 'נא לרשום את השאלה!' }]}
                >
                  <TextArea
                    // defaultValue={task.prompt}
                    rows={4}
                    placeholder="שאלה"
                    name="prompt"
                  />
                </Form.Item>
                <br />
                <Form.Item
                  name="solution"
                  rules={[{ required: true, message: 'יש לבחור תשובה' }]}
                >
                  <SelectField
                    text="תשובה"
                    options={solutionOptions}
                    name="solution"
                    // defaultValue={solutionOptions.find(
                    //   (option) => option.value === task.solution,
                    // ).value}
                    placeholder="בחר תשובה"
                  />
                </Form.Item>
                <br />
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item>
                      <Button
                        square
                        fullWidth
                        small
                        htmlType="submit"
                      >
                        שמירה
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <Button
                        onClick={(ev) => { setEditTaskId(null); }}
                        square
                        fullWidth
                        small
                      >
                        ביטול
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>
      ))}
      <div />
    </Page>
  );
};

export default GuideManageTasks;
