export const setUserSettings = (settings) => {
  localStorage.setItem('userSettings', JSON.stringify(settings));
};

export const getUserSettings = () => {
  const settings = localStorage.getItem('userSettings');
  return settings ? JSON.parse(settings) : {};
};

export const setUserSetting = (setting) => {
  const settings = getUserSettings();
  settings[setting.key] = setting.value;
  setUserSettings(settings);
};
