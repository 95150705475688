import React, {
  useContext, useState,
} from 'react';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import UserContext from '../contexts/userContext';
import './UserProfileContainer.scss';
import StudentMainPage from '../layout/StudentMainPage';
import DefaultAvatar from '../images/default-avatar-header.png';
import UserRank from '../components/UserRank';
import ChabadModal from '../components/Modal';
import avatars from '../enums/avatars';
import Loader from '../components/Loader';

const UserProfileContainer = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [showChooseAvatarModal, setShowChooseAvatarModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const logout = async (ev) => {
    ev.preventDefault();
    await Axios.get('/api/logout');
    history.push('/login');
  };

  const onChangeAvatar = async (avatarId) => {
    setLoading(true);
    await Axios.post('/api/users/avatar', {
      avatarId,
    });
    setShowChooseAvatarModal(false);
    window.location.reload();
  };

  if (!user) return '';
  return (
    <StudentMainPage>
      {loading && <Loader />}
      <ChabadModal
        open={showChooseAvatarModal}
        title="בחר אוואטר"
        onCancel={() => { setShowChooseAvatarModal(false); }}
        body={(
          <div>
            <div className="choose-avatar">
              <div key="avatar1" className="avatar" onClick={() => { onChangeAvatar(avatars.avatar1.id); }} role="button" tabIndex={-1}>
                <img src={avatars.avatar1.image} alt="avatar1" />
              </div>
              <div key="avatar2" className="avatar" onClick={() => { onChangeAvatar(avatars.avatar2.id); }} role="button" tabIndex={-1}>
                <img src={avatars.avatar2.image} alt="avatar2" />
              </div>
              <div key="avatar3" className="avatar" onClick={() => { onChangeAvatar(avatars.avatar3.id); }} role="button" tabIndex={-1}>
                <img src={avatars.avatar3.image} alt="avatar3" />
              </div>
              <div key="avatar4" className="avatar" onClick={() => { onChangeAvatar(avatars.avatar4.id); }} role="button" tabIndex={-1}>
                <img src={avatars.avatar4.image} alt="avatar4" />
              </div>
            </div>
          </div>
        )}
      />
      <div className="user-profile">
        <div className="student-sub-header-darkbrown-slices" />
        <div className="user-profile-avatar" role="button" tabIndex={-1} onClick={() => { setShowChooseAvatarModal(true); }}>
          <EditOutlined />
          <img src={avatars[user.avatar]?.image || DefaultAvatar} alt="avatar" className="user-profile-avatar" />
        </div>
        <div className="user-profile-background-yellow" />
        <div className="user-profile-container">
          <div className="space-lg" />
          <div className="user-fullname">
            <span>
              {user.name}
              {' '}
              {user.lastName}
            </span>
          </div>
          <div className="divider" />
          <div className="user-stars">
            <span className="star-icon" />
            <span className="user-count">{user.stars || 0}</span>
          </div>
          <div className="user-parashot user-ranks-wrapper">
            <UserRank showRanksNames showCurrentStep user={user} />
          </div>
          <div className="user-parashot">
            <div>
              <div>פרשות</div>
              <div className="medium-text"><b>{user.parashot?.[0]?.name || 'נא לבחור פרשה'}</b></div>
              <div className="medium-text"><b>{user.parashot?.[1]?.name}</b></div>
            </div>
            <div className="image-parashot" />
            <div>
              <div>הפטרה</div>
              <div className="medium-text"><b>{user.haftaraFull?.name ?? 'ללא'}</b></div>
            </div>
          </div>
          <div className="logout-button">
            <LogoutOutlined />
            <Link onClick={logout} to="#">התנתק</Link>
          </div>
        </div>

      </div>
    </StudentMainPage>
  );
};

export default UserProfileContainer;
