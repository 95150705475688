import Ahrayot from '../images/missions/ahrayot-mission.png';
import AronKodesh from '../images/missions/aron-kodesh-mission.png';
import Bagrut from '../images/missions/bagrut-mission.png';
import BeitKneset from '../images/missions/beit-kneset-mission.png';
import BrachotHanenim from '../images/missions/brachot-hanenim-mission.png';
import Manhigot from '../images/missions/manhigot-mission.png';
import Netina from '../images/missions/netina-mission.png';
import Shma from '../images/missions/shma-mission.png';
import TaamiMikra from '../images/missions/taami-mikra.png';
import TalitTfilin from '../images/missions/talit-tfilin-mission.png';

const missioNameToImage = {
  shma: Shma,
  'taami-mikra': TaamiMikra,
  'brachot-hanenim': BrachotHanenim,
  'aron-kodesh': AronKodesh,
  'talit-tfilin': TalitTfilin,
  'beit-kneset': BeitKneset,
  netina: Netina,
  bagrut: Bagrut,
  manhigot: Manhigot,
  ahrayot: Ahrayot,
};

export default missioNameToImage;
