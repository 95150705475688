import * as React from 'react';
import RanksProgressEmpty from '../images/ranks/progress_empty.png';
import RanksProgress1 from '../images/ranks/progress_1.png';
import RanksProgress2 from '../images/ranks/progress_2.png';
import RanksProgress3 from '../images/ranks/progress_3.png';
import RanksProgress4 from '../images/ranks/progress_4.png';

import Kipa from '../images/ranks/kipa.svg';
import Tfilin from '../images/ranks/tfilin.svg';
import Talit from '../images/ranks/talit.png';
import Sidor from '../images/ranks/sidor.png';
import './UserRank.scss';

const ranks = [{
  minStars: 160,
  name: 'tfilin',
  progressImage: RanksProgress4,
  displayName: 'תפילין',
  image: Tfilin,
  step: 4,
}, {
  minStars: 115,
  name: 'talit',
  progressImage: RanksProgress3,
  displayName: 'טלית',
  image: Talit,
  step: 3,
}, {
  minStars: 75,
  name: 'sidor',
  progressImage: RanksProgress2,
  displayName: 'סידור',
  image: Sidor,
  step: 2,
}, {
  minStars: 40,
  name: 'kipa',
  progressImage: RanksProgress1,
  displayName: 'כיפה',
  image: Kipa,
  step: 1,
}];

export const getUserRank = (userStars) => {
  const userRank = ranks.find((rank) => userStars >= rank.minStars);
  return userRank;
};

const UserRanks = ({ user, showCurrentStep, showRanksNames }) => {
  const userRank = getUserRank(user.stars);

  return (
    <div className="user-ranks">
      {showCurrentStep && (
        <div className="current-step">
          <b>
            <span>
              שלב
              {' '}
              {userRank?.step || 0}
              {' '}
              / 4
            </span>

          </b>
        </div>
      )}
      <img src={userRank ? userRank.progressImage : RanksProgressEmpty} alt="התחלה" />
      {showRanksNames && (
        <div className="rank-display-names">
          {ranks.map((rank) => (
            <div key={rank.step} className="rank-display-name">
              {rank.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(UserRanks);
