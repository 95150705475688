import React from 'react';
import './selectField.scss';

const SelectField = ({
  text, name, placeholder, onChange, options, defaultValue, value, disabled,
}) => (
  <div>
    <div className="select-field">
      <div className="select-lbl"><span>{text}</span></div>
      <select
        value={value} // prob not being used
        defaultValue={value ? undefined : (defaultValue || placeholder)}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className="select-field"
        onChange={onChange}
      >
        <option key="placeholder" disabled>{placeholder}</option>
        {options.map(
          (opt) => (
            <option
              key={opt.value}
              value={opt.value}
            >
              {opt.text}
            </option>
          ),
        )}
      </select>
    </div>
  </div>
);

export default SelectField;
