import React from 'react';
import UserContext from '../contexts/userContext';
import MapContainer from './MapContainer';

const HomePage = () => {
  const user = React.useContext(UserContext);

  if (!user) return '';

  if (user.isGuide) window.location.assign('/admin/guide');
  if (user.isManager) window.location.assign('/admin/manager');

  return (
    <>
      {user.isStudent && <MapContainer />}
    </>
  );
};

export default HomePage;
