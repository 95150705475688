import React from 'react';
import { Tooltip, Button, Modal } from 'antd';
import DeleteIcon from '../svgs/delete.svg';
import RestoreIcon from '../svgs/forward-restore.svg';
import EditIcon from '../svgs/edit.svg';
import './UserItemList.scss';
import RowBoxItemList from './RowBoxItemList';
import statusEnum from '../enums/status.enum';
import './BasicModal.scss';

function UserItemList({
  userId, name, count, countText, onClick, status,
  onDeleteClick, onEditClick, showEditBtn, onRestoreClick,
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const handleDeleteOk = () => {
    onDeleteClick(userId);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteButtonClick = React.useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDeleteModalOpen(true);
  }, []);

  return (
    <>
      <div className="row-box-item-list-user">
        <Modal
          title="מחיקת משתמש"
          visible={isDeleteModalOpen}
          okText="מחיקה"
          cancelText="ביטול"
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        >
          <div>
            <span>
              האם הינך בטוח שברצונך למחוק את המשתמש
              {' '}
              {name}
              ?
            </span>
          </div>
        </Modal>
        <RowBoxItemList
          tabIndex={0}
          role="button"
          onClick={(ev) => onClick && onClick(ev, userId)}
          key={userId}
        >
          <div className="avatar-wrapper">
            {status !== undefined ? (
              <span>
                {status === statusEnum.active ? <span className="green-dot" /> : <span className="red-dot" />}
              </span>
            ) : ''}
            <div className="avatar-small" />
          </div>
          <div className="name">
            <span>
              {name}
            </span>
          </div>
          <div>
            <span className="students-number">
              {count || 0}
            </span>
          </div>
          <div>
            <span className="talmidim">
              {countText}
            </span>
          </div>
          <div className="user-item-buttons">
            {showEditBtn && (
              <Tooltip title="עריכה">
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onEditClick(userId);
                  }}
                  type="link"
                >
                  {/* <EditIcon /> */}
                  <img style={{ marginBottom: '-5px' }} src={EditIcon} alt="עריכה" />
                </Button>
              </Tooltip>
            )}
            {onDeleteClick && (
              <Tooltip title="מחיקה">
                <Button
                  onClick={onDeleteButtonClick}
                  type="link"
                >
                  <img src={DeleteIcon} alt="מחיקה" />
                </Button>
              </Tooltip>
            )}
            {onRestoreClick && (
              <Tooltip title="שחזור משתמש">
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onRestoreClick(userId);
                  }}
                  type="link"
                >
                  <img width="20" src={RestoreIcon} alt="מחיקה" />
                </Button>
              </Tooltip>
            )}
          </div>
        </RowBoxItemList>
      </div>
    </>
  );
}

export default UserItemList;
