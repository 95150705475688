import React from 'react';
import './footerWithButton.scss';

const FooterWithButton = ({ btnText, btnClick, children }) => (
  <div className="footer-with-button">
    <button type="button" onClick={btnClick} className="add-btn">
      {btnText}
    </button>
    {children}
  </div>
);

export default FooterWithButton;
