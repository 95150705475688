import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Input as AntdInput, Col, Form, Row,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import './GuideScreenContainer.scss';
import Page from './Page';
import UserContext from '../contexts/userContext';
import './GuideManageTasks.scss';
import Button from '../foundation/Button';
import Loader from '../components/Loader';

const { TextArea } = AntdInput;

const GuideManageTasks = () => {
  const user = React.useContext(UserContext);
  const [guideBonuses, setGuideBonuses] = useState();
  const [editBonusId, setEditBonusId] = useState();
  const [loading, setLoading] = useState();
  const [missions, setMissions] = useState();

  const getBonuses = React.useCallback(async () => {
    if (!user) return;

    const guideBonusRes = await axios.get(`/api/guides/${user.userId}/bonuses`);
    setGuideBonuses(guideBonusRes.data);
  }, [user]);

  useEffect(() => {
    const getMissions = async () => {
      const missionsRes = await axios.get('/api/missions');
      const missionsData = {};
      missionsRes.data.forEach((mission) => {
        missionsData[mission.missionId] = mission;
      });
      setMissions(missionsData);
    };
    getMissions();
    getBonuses();
  }, [getBonuses]);

  const editTask = (bonusId) => {
    setEditBonusId(bonusId);
  };

  const submitBonusPrompt = async (values) => {
    setLoading(true);
    try {
      await axios.post(`/api/guides/bonuses/${editBonusId}`, values);
      await getBonuses();
    } finally {
      setLoading(false);
      setEditBonusId(null);
    }
  };

  if (!guideBonuses || !missions) return null;

  return (
    <Page
      title={user?.name}
    >
      {loading && <Loader />}
      {guideBonuses.map((guideBonus) => (
        <div className="edit-task" key={guideBonus.bonusId}>
          <div className="edit-task-title">
            <div>
              <span>
                <b>משימה:</b>
                {' '}
                {missions[guideBonus.missionId].txt}
              </span>
            </div>
            <div>
              {!editBonusId && (
                <EditOutlined onClick={() => editTask(guideBonus.bonusId)} style={{ fontSize: '1.2em' }} />
              )}
            </div>
          </div>
          {editBonusId !== guideBonus.bonusId ? (
            <div className="edit-task-content">
              <div>
                <b>משימת בונוס יומית:</b>
                {' '}
                {guideBonus.prompt || guideBonus.bonusPrompt}
              </div>
              <div>
                <b>משימת בונוס שבועית:</b>
                {' '}
                {guideBonus.promptWeekly || guideBonus.bonusPromptWeekly}
              </div>
            </div>
          ) : (
            <div>
              <br />
              <Form
                name="basic"
                onFinish={submitBonusPrompt}
                initialValues={{
                  prompt: guideBonus.prompt || guideBonus.bonusPrompt,
                  promptWeekly: guideBonus.promptWeekly || guideBonus.bonusPromptWeekly,
                }}
              >
                <span>משימת בונוס יומית</span>
                <Form.Item
                  wrapperCol
                  name="prompt"
                  rules={[{ required: true, message: 'נא לרשום את הבונוס!' }]}
                >
                  <TextArea
                    // defaultValue={task.prompt}
                    rows={4}
                    placeholder="בונוס"
                    name="prompt"
                  />
                </Form.Item>
                <br />
                <span>משימת בונוס שבועית</span>
                <Form.Item
                  wrapperCol
                  name="promptWeekly"
                  rules={[{ required: true, message: 'נא לרשום את הבונוס השבועי!' }]}
                >
                  <TextArea
                    rows={4}
                    placeholder="בונוס שבועי"
                    name="promptWeekly"
                  />
                </Form.Item>
                <br />
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item>
                      <Button
                        square
                        fullWidth
                        small
                        htmlType="submit"
                      >
                        שמירה
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <Button
                        onClick={(ev) => { setEditBonusId(null); }}
                        square
                        fullWidth
                        small
                      >
                        ביטול
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>
      ))}
      <div />
    </Page>
  );
};

export default GuideManageTasks;
