/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import './ButtonStyle.scss';
import { Button as AntButton } from 'antd';

const Button = ({
  red, small, square, fullWidth, ...props
}) => {
  let classes = red ? 'red-btn' : 'green-btn';
  if (small) classes += ' btn-small';
  if (square) classes += ' btn-square';
  if (fullWidth) classes += ' btn-full-width';
  return <AntButton htmlType={props.htmlType} className={classes} {...props} />;
};

export default Button;
