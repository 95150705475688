/** Convert a 2D array into a CSV string
 */
export const arrayToCSV = (data) => {
  if (!data || !data.length) return '';

  let csv = Object.entries(Object.entries(data)[0][1]).map((e) => e[0]).join(',');
  for (const [k, v] of Object.entries(data)) {
    csv += `\r\n${ Object.values(v).join(',')}`; // \n is enough in linux to reduce the csv size
  }
  /* //Uncomment for file download
    let j = document.createElement("a")
    j.download = "example_"+Date.now()+".csv"
    j.href = URL.createObjectURL(new Blob([csv]))
    j.click() */
  return csv;
};

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const downloadBlob = (content, filename, contentType) => {
  // Create a blob
  const blob = new Blob([String.fromCharCode(0xFEFF) + content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const mapStudentToCSV = (student) => ({
  userId: student.userId,
  name: student.name,
  lastName: student.lastName,
  phoneNumber: student.phoneNumber,
  motherName: student.motherName,
  fatherName: student.fatherName,
  address: student.address,
  parentPhone: student.parentPhone,
  birthDate: student.birthDate,
  stars: student.stars,
  mosadName: student.mosadName,
  comment: student.comment,
});
