import { Tabs } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './Page.scss';
import HamburgerMenu from '../components/hamenu';
import Logo from '../components/Logo';
import arrow from '../svgs/Arrow_right.svg';

const { TabPane } = Tabs;

const Page = ({
  title, onTabChange, children, tab1, tab2, tab3, showBackBtn, defaultTabKey,
}) => {
  const history = useHistory();
  function imgClick() {
    history.goBack();
  }
  return (
    <div className="page-wrapper">
      {showBackBtn ? (
        <div className="back-button">
          <img
            src={arrow}
            alt="back"
            onClick={imgClick}
            aria-hidden="true"
          />

        </div>
      ) : <HamburgerMenu />}
      <div className="app-header">
        <br />
        <span className="program-head-name">{title}</span>
        <Logo logo_css="logo-manager-container" />
        <br />
        {onTabChange && (
          <Tabs className="tabs-wrapper" defaultActiveKey={defaultTabKey || '1'} onChange={onTabChange}>
            <TabPane tab={tab1} key="1" tabPosition="right" />
            <TabPane tab={tab2} key="2" tabPosition="left" />
            {tab3 && <TabPane tab={tab3} key="3" tabPosition="left" />}
          </Tabs>
        )}
      </div>
      {children}
    </div>
  );
};
export default Page;
