import React, { useState } from 'react';
import axios from 'axios';
import { Alert, Input as AntdInput } from 'antd';
import './LoginPage.scss';

const LoginPage = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const login = async () => {
    setError(null);
    let userRes;
    try {
      userRes = await axios.post('/api/login', { phone, password });
    } catch (e) {
      setError('פרטי ההתחברות שגויים');
      return;
    }
    if (userRes.isGuide) window.location.assign('/admin/guide');
    if (userRes.isManager) window.location.assign('/admin/manager');
    window.location.assign('/');
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <div className="login-welcome"><span>&nbsp;&nbsp;&nbsp;&nbsp;ברוכים הבאים!</span></div>
        <span className="font-dakar">אנא הכנס פרטי התחברות</span>
        <div>
          <AntdInput
            className="input-field"
            name="phoneNo"
            type="tel"
            onChange={(ev) => setPhone(ev.target.value)}
            placeholder="טלפון"
            onPressEnter={login}
          />
        </div>
        <div>
          <AntdInput
            className="input-field"
            name="password"
            type="password"
            onChange={(ev) => setPassword(ev.target.value)}
            placeholder="סיסמא"
            onPressEnter={login}
          />
        </div>
        {error && (
          <div>
            <Alert message={error} type="error" />
          </div>
        )}
        <div>
          <button onClick={login} type="button" className="login-button">התחברות</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
